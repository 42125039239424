<!-- Remesas de Cobros / Pagos -->

<template>
  <div class="cobros_pagos_remesas">   
      <div class="centrado">
        <v-sheet :elevation="4">        
          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="event_Header">
            </base_Header>                  
          </div>

          <!-- Contenido -->
          <!-- <div class="conflex" >  -->
            <div class="columna" style="width:850px">
       
              <!-- Grid -->
              <base_Fgrid
                v-model="selected"
                :padre="stName" 
                :Entorno="Entorno"  
                height="400px"        
                :items-per-page=-1 
                show-select 
                single-select
                hide-default-footer 
                fixed-header 
                dense
                @onEvent="event_Grid">

                <!-- Slot botonera Top -->
                <template v-slot:top_btns="{ }">         
                  <div style="padding:10px 0 0 10px;">
                    <div style="display:flex;justify-content:space-between;width:750px;padding-bottom:15px">
                      <cobros_pagos_ultimasRemesas
                        :padre="padre"
                        @onEvent="get_remesa">
                      </cobros_pagos_ultimasRemesas>
                      
                      <cobros_pagos_frasRemesa
                        :padre="padre"
                        :records="selected.length? selected[0].facturas : null"
                        :disabled="!selected.length">
                      </cobros_pagos_frasRemesa>

                      <btraextra
                        :btra_permisos="btra_permisos"
                        :btra_particular="Entorno.btra"
                        btra_modulo="standard"
                        @onEvent="event_btra_Top">              
                      </btraextra>   

                      <v-text-field
                        style="flex: 1 1 40%;padding-left:10px"
                        v-bind="$input"
                        v-model="schema.ctrls.txt.value"
                        :label="schema.ctrls.txt.label"
                        append-icon="mdi-magnify"
                        @keypress.13="filterCuenta"
                        @click:append="filterCuenta">
                      </v-text-field>
                    </div>           
                  </div>
                </template>    

                 <template v-slot:cta="{ Sitem }">    
                    <!-- {{ Sitem.cta }} -->                    
                    <div :class="Sitem.dev_fh>''?'red--text':'blue--text'" > {{ Sitem.cta }}</div>
                  </template>

              </base_Fgrid>                       

              <div class="contenedor">
                <div class="cab">DATOS REMESA</div>
                <v-sheet v-bind="$cfg.styles.marco">
                  <div class="conflex" style="padding-top:10px">
                    <vlinput 
                      style="flex: 0 0 26%"
                      label="Fecha "
                      type="date" 
                      v-model="schema.ctrls.fh.value" 
                      disabled> 
                    </vlinput>
            
                    <vllabel
                      style="flex: 0 0 11%"
                      label="Ctas"
                      :txt="schema.ctrls.ctas.value">
                    </vllabel>

                    <vllabel
                      style="flex: 0 0 11%"
                      label="Fras"
                      :txt="schema.ctrls.fras.value">
                    </vllabel>

                    <vllabel
                      style="flex: 0 0 19%"
                      label="Total"
                      :txt="schema.ctrls.tot.value|num(2)">
                    </vllabel>

                    <vllabel
                      style="flex: 0 0 15%"
                      label="Asiento"
                      :txt="schema.ctrls.asi.value">
                    </vllabel>

                    <vllabel
                      style="flex: 0 0 15%"
                      label="Ope"
                      :txt="selected.length && selected[0].conta_usu!=''? selected[0].conta_usu :''">
                    </vllabel>
                                        
                    <hr>

                    <vllabel
                      style="flex: 0 0 30%"
                      label="Email"
                      :txt="selected.length && selected[0].email_fh!= ''? ($options.filters['fechahr'](selected[0].email_fh)) : ''">
                    </vllabel>

                    <vllabel
                      style="flex: 0 0 30%"
                      label="* Devolución"
                      :txt="selected.length && selected[0].dev_fh!= ''? ($options.filters['fechahr'](selected[0].dev_fh)) : ''">
                    </vllabel>
                    
                  </div>                      
                </v-sheet>
              </div>
            </div>
          <!-- </div> -->
        </v-sheet> 
      </div>
      
      <!-- Ventana Expedientes -->
      <!-- <v-dialog
        v-model="modal"
        content-class="modal"
        persistent>
          <div class="centrado">
            <cobros_pagos_ultimasRemesas
              :padre="stName"
              :accion="accion"
              :accionRow="accionRow"
              :disparo="disparo"
              @cerrar="modal=false"
              @get_remesa="get_remesa">            
            </cobros_pagos_ultimasRemesas>                
          </div>
      </v-dialog> -->

  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const btraextra = () => plugs.groute("btraextra_new.vue", "comp");
  const cobros_pagos_ultimasRemesas = () => plugs.groute("cobros_pagos_ultimasRemesas.vue", "comp");
  const cobros_pagos_frasRemesa = () => plugs.groute("cobros_pagos_frasRemesa.vue", "comp");
    
  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, btraextra, cobros_pagos_ultimasRemesas, cobros_pagos_frasRemesa },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:String, default: ''},
      accionRow: { type:Object, default: null},
      disparoFinder: { type:Boolean, default: false},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {
            fh: { f:'fh', label:'Fecha', value:""},
            ctas: { f:'ctas', label:'Cuentas', value:""},
            fras: { f:'fras', label:'Facturas', value:""},
            tot: { f:'tot', label:'Importe', value:""},
            asi: { f:'asi', label:'Asiento', value:""},            
            txt: { f:'txt', label:'Búsqueda (Cuenta)', value:""}
          }
        },
        api:'',
        stName:'stFcobros_pagos_remesas',
        headers: {},
        btra_permisos:{ extra:{}},
        remesa:'',
        tipo:0,
        selected:[],

        dialog:false,
        itemsCC: [],
        itemsTipos: []
      };
    },



    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // configuro Entorno 
        this.Entorno.header.titulo = ""; 
        this.Entorno.grid.autoload= true;
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo[this.Entorno.btra]));
        this.Entorno.btra.footer.extra = [
          { accion: "eliminar_pago", icono: "mdi-delete", title:'Eliminar Pago' },          
          { accion: "email", icono: "mdi-email-outline", title:'Envio de email a la cuenta seleccionada' },
          { accion: "informe", icono: "mdi-file-chart", title:'Informe Listado Remesa' },
          { accion: "remesa_enviar", icono: "mdi-alpha-r-circle-outline", title:'Generar Remesa y envio Email' },          
          { accion: "emailAll", icono: "mdi-email-multiple-outline", title:'Envio de email a todas las cuentas' },
          { accion: "conta", icono: "mdi-alpha-c-circle", title:'Contabilizar Remesa' },
          { accion: "devolucion", icono: "mdi-alpha-d-circle", title:'Devolución Pago/Facturas' },
          
        ]; 

        this.headers = {
          header: [
            { text: "Cuenta", value: "cta", witdh: "70%", slot:true },
            { text: "Fras", value: "fras", witdh: "5%" },
            { text: "Total", value: "tot", witdh: "25%" }
         ],

        };

        this.Entorno.grid.headers = this.set_headers();
      
        // items adicionales
        // array de cuentas bancarias
        this.itemsCC= [];

        // array de tipos de operaciones
        this.itemsTipos= []
      },


      // obtengo los registros a mostrar
      async ini_component(rem= 0) {
        // limpio selected
        this.selected= [];

        let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          accion:'remesas',
          fnt:'get',
          rem: rem
        }};
        console.log('args get_datos: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult get_datos: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // guardo records del Grid todos los arrays recibidos, en 'recordsDetalle'
        this.$store.commit(this.stName + '/update_records', apiResult.r[0]);
        this.$store.commit(this.stName + '/data2State', { prop:'recordsDetalle', value: apiResult.r }); 
        if (apiResult.r[0].length) this.selected= [apiResult.r[0][0]];
        
        // calculo totales
        this.calcular_totales();        
      },


      // 
      calcular_totales() {
        // botonera
        this.botoneras_set();
        
        // calculo totales
        this.schema.ctrls.fras.value= 0, this.schema.ctrls.ctas.value= 0, this.schema.ctrls.tot.value= 0;
        
        this.miSt.records.map(elem => {          
          this.schema.ctrls.fras.value= Number(this.schema.ctrls.fras.value) + Number(elem.fras);
          this.schema.ctrls.ctas.value= Number(this.schema.ctrls.ctas.value) + 1;
          this.schema.ctrls.tot.value= Number(this.schema.ctrls.tot.value) + Number(elem.tot);
        });

        if (!this.miSt.records.length) return;
        this.schema.ctrls.asi.value= this.miSt.records[0].conta_asi;
        this.schema.ctrls.fh.value= this.miSt.records[0].fh;
        this.remesa= this.miSt.records[0].rem;
        this.tipo=this.miSt.records[0].tipo_cta;
        this.$set(this.Entorno.header, 'titulo', 'Remesa ' + this.remesa);
      },


      // configuración botonera
      botoneras_set() {                
        this.btnSet("conta",{ disabled: this.schema.ctrls.asi.value=='0'? false : true });
        this.btnSet("eliminar_pago",{ disabled: this.selected.length==0 || this.selected[0].asi!='0' || !this.selected.length? true : false });
        //this.btnSet("eliminar_pago",{ disabled:!this.selected.length? true : false });
        this.btnSet("email",{ disabled:!this.selected.length? true : false });
        this.btnSet("devolucion",{ 
          disabled: !this.selected.length || this.schema.ctrls.asi.value=='0' || this.selected[0].tipo_cta=='9' ? true : false });
      },

    
      // guardo la remesa recibida y la cargo
      get_remesa(evt) {
        this.remesa= evt.rem;
        this.ini_component(evt.rem);
      },


      // ventana listado últimas remesas
      lista_remesa() {
        this.modal= true;
        this.disparo= !this.disparo;
      },


      // listado facturas remesa seleccionada
      async informe() {
        let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          accion:'remesas',
          fnt:'listado',
          rem:this.remesa,
          env:1
        }};
       
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });      
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        window.open(this.$store.state.G.url_tmp + apiResult.r[0], "_blank");
      },


      // envio de email a la cuenta seleccionada
      email() {
        if (this.tipo=='9') { this.$root.$alert.open('Es Pago de Liquidación de Apds.<br>Debes enviarla desde Liquidaciones de APDS', 'info'); return; }
        
        if (!this.selected.length) {
          this.$root.$alert.open('Debe seleccionar una cuenta', 'info');
          return;
        }

        this.$root.$alert.open('Enviamos eMail Orden de Pago?<br>Cuenta: '
                               + this.selected[0].cta + '<br>Facturas: ' + this.selected[0].fras
                               + '<br>Importe: ' + this.selected[0].tot, 'confirm')
        .then(r=> { if (r) this.envio_email(); });
      },

      async envio_email() {
        let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          accion:'remesas',
          fnt:'email',
          rem:0,
          idP:this.selected[0].id
        }};
        console.log('args email: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult get_datos: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Email enviado correctamente', 'success', 1800);
      },

      // envio de email a todas las cuentas
      async emailAll() {
        if (this.tipo==9) { 
          this.$root.$alert.open('Son Pagos de Liquidaciones de Apds.<br>Debes enviarla desde Liquidaciones de APDS', 'info'); return; }

        this.$root.$alert.open('Enviamos eMail Ordenes de Pago de Remesa? ' + this.remesa, 'confirm')
          .then(r=> { if (r) this.envio_emailAll(); });
      },

      async envio_emailAll() {
        let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          accion:'remesas',
          fnt:'email',
          rem:this.remesa
        }};
        console.log('args emailAll: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult get_datos: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Emails enviados', 'success');
      },


      //
      remesa_enviar() {
        this.$root.$alert.open('Generamos/Enviamos REMESA ' + this.remesa, 'confirm')
        .then(r=> { if (r) this.remesa_enviar_continue(); });
      },

      async remesa_enviar_continue() {
        let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          accion:'remesas',
          fnt:'remesa',
          rem:this.remesa
        }};
        console.log('args remesa_enviar_continue: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult get_datos: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        let res=apiResult.r[0]; //num,tot,err,errores

        this.$root.$alert.open('Remesa <b>' + this.remesa + '</b> generada (email).<br>' + 
          '* Transferencias: <b>' + res[0] + '</b> Importe: <b>' + res[1] + '</b><br>' +
          '* Errores: <b>' + res[2] + '</b>' + (res[2]?'<hr>' + res[3] : '') + '<br>'
          , 'success');
      },


      //  
      conta() {
        this.$root.$alert
        .open("¿CONTABILIZAMOS / LIQUIDAMOS REMESA " + this.remesa + "?", "confirm")
        .then(r => { if (r) this.conta_continue(); });
      },

      async conta_continue() {
        let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          accion:'remesas',
          fnt:'conta',
          rem:this.remesa
        }};
        console.log('args conta_continue: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult get_datos: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Remesa Contabilizada. Asiento nº <b>' + apiResult.r[0] + '</b>', 'success');

        // recargo remesa
        this.ini_component(this.remesa);
      },


      // borro el pago seleccionado
      eliminar_pago() {
        this.$root.$alert.open('¿Desea ELIMINAR el pago ?' + '<br>Se elimina de la REMESA y se actualiza la/s FACTURA/S <br>' + 
          (this.selected[0].conta_asi>0 ? 'Ya esta contabilizado pero no enviado por lo que recontabilizara la remesa' : '')
          , 'confirm')
        .then(r=> { if (r) this.eliminar_pago_continue(); });
      },

      async eliminar_pago_continue() {
        let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          accion:'pagos',
          fnt:'set',
          idP:-this.selected[0].id
        }};
        console.log('args email: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult get_datos: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }               

        // recargo remesa
        this.ini_component(this.$store.state[this.stName].records.length==1? 0 : this.remesa);

        //
        this.$emit('reloadCuentas');
      },

      devolucion() {
        let P=this.selected[0];
        if (P.dev_fh) {
          this.$root.$alert.open('Esta Pago Ya ha sido Devuelto <b>' + P.dev_fh + '</b>', 'error');
          return;
        }

        this.$root.$alert.open("¿ DEVOLUCION PAGO/FACTURAS ?<hr><br>" +
          "Cuenta:<b> " + P.cta + "</b><br>" + 
          "Facturas: <b> " + P.fras + '</b><br>' +
          "Importe: <b> " + P.tot + '</b><br><br>'
          ,"confirm")
        .then(r => { if (r) this.devolucion_continue(); });
      },

      async devolucion_continue() {
        let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          accion:'pagos',
          fnt:'devo',
          id: this.selected[0].id,
          //fh:
        }};
        console.log('args devolucion_continue: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult devolucion: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        let r=apiResult.r; //idD,asi
        this.$root.$alert.open('Devolución Generada Asiento nº <b>' + r[1] + '</b>', 'success');

        // recargo remesa
        this.ini_component(this.remesa);
      },

      
      // 
      filterCuenta() {      
        let txt= this.schema.ctrls.txt.value;
              
        this.$store.commit(this.stName + '/data2State', { prop:'records', value:this.miSt.read_records.slice().filter(elem=> {
        
          if (txt!= '') {          
            return (
              elem.cta.toLowerCase().includes(txt.toLowerCase())
            )
          }

          return elem;
        })})
        
        // limpio selección
        this.selected= [];

        // calculo totales
        this.calcular_totales();        
      },


      //      
      event_Header() {        
        if (this.padre=='') {
          this.$router.push({ path:'/' });
          return;
        }

        this.$emit('onEvent', { accion:'cerrar' });
      },
      

    },


    watch: {
      selected() {
        this.botoneras_set();
      }
    }

  };
</script>
